import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Introduction`}</h3>
    <p>{`Coqui Studio is thrilled to announce the release of its latest feature, Voice Fusion! This innovative new
technology allows users to combine any two generative AI voices to create a unique and never-before-heard
voice. Whether you’re a video game developer, an audio post-production professional, or working on dubbing,
Voice Fusion has got you covered.`}</p>
    <h3 {...{
      "id": "voice-fusion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#voice-fusion",
        "aria-label": "voice fusion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Voice Fusion`}</h3>
    <p>{`The process of creating a fused voice is simple and intuitive. All you have to do is select two existing
generative AI voices, such as out-of-the-box voices that ship with Coqui Studio or cloned voices, and adjust
the proportion in which they are combined. For example, you can choose to have 50% of Abrahan Mack’s voice
and 50% of Alison Dietilnde’s voice, to create a voice that’s uniquely yours!`}</p>
    <div align="center">
  <iframe style={{
        "width": "408px",
        "height": "520px",
        "borderWidth": "1px 0px 1px 1px",
        "borderStyle": "solid solid solid solid",
        "borderColor": "black black black black"
      }} src="https://youtube.com/embed/2bUBYXEtvvw?autoplay=1&loop=1&playlist=2bUBYXEtvvw" allow="accelerometer;
        autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
    <p>{`Once the fused voice has been created, it can be used in Coqui Studio just like any other voice. You have
complete control over the emotion and pacing of the voice, as well as its word-level and phoneme-level
duration, pitch, and volume. This means you can create truly custom and unique voices for your projects,
giving them a distinct and personalized touch.`}</p>
    <h3 {...{
      "id": "conclusion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#conclusion",
        "aria-label": "conclusion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Conclusion`}</h3>
    <p>{`Voice Fusion is an exciting addition to Coqui Studio’s suite of tools for creatives. Whether you’re working on video
games, audio post-production, or dubbing, you can now create voices that are truly one-of-a-kind. So why wait? Start
experimenting with Voice Fusion today and discover the limitless possibilities it offers!`}</p>
    <div align="center">
  👉 <a href="https://app.coqui.ai/auth/signup">Try Coqui Studio Now for Free!</a>
    </div>
    <br />
    <p>{`We can’t wait to see what you create with Voice Fusion! If you have any questions or comments, please don’t hesitate
to reach out to us.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      